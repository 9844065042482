.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 200px;
}
.loader{
  background-color: black;
  padding: 50px;
  color: rgb(255, 106, 0);
}
body{
  background-color: rgb(4, 41, 58);
}
.introvideo{
  width: 200px;
  height: 200px;
  border-radius: 90px;
  box-shadow: 1px 1px 10px  rgb(4, 41, 58) ;
}